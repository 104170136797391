<template>
  <div>
    <div class="" style="width: 100%">
      <img style="display: block;width: 1920px;margin: auto;" src="../../assets/images/djt/ptfwjs.jpg" alt="">
    </div>
    <div class="bg">
      <div class="wrapper">
        <div>
          <div _ngcontent-c13="" class="page1">
            <h2 _ngcontent-c13="" class="center">培训平台服务介绍</h2>
            <p _ngcontent-c13="" class="em2">交通运输行业网络安全大讲堂除进行课程培训外，还提供培训平台服务，可满足不同行业、不同规模企业在线培训需求。</p>
            <img src="../../assets/images/djt/page2-1.png" alt="" style="display: block;width: 1000px;margin: 50px auto 0;">
          </div>
    </div>
      </div>
    </div>
  </div>

  
</template>

<style lang="less" scoped>
  .bold {
    font-weight: bold;
  }
  .left {
    margin: 20px 0;
    font-size: 22px;
  }
  .em2{
  text-indent: 2em;
  line-height: 40px;
}
.center{
  text-align: center;
}
.img {
  width: 100%;
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}
.img img {
  width: 600px;
}
  .bg {
    color: #333;
    font-size: 16px;
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #217aff;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
